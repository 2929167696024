/*
@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal; !*400*!
  src: local('Gotham Book'), url('fonts/GothamBook.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: normal; !*400*!
  src: local('Gotham Book Italic'), url('fonts/GothamBookItalic.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 200;
  src: local('Gotham Light'), url('fonts/GothamLight.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 200;
  src: local('Gotham Light Italic'), url('fonts/GothamLightItalic.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  src: local('Gotham Medium'), url('fonts/GothamMedium.woff') format('woff');
}

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  src: local('Gotham Medium'), url('fonts/GothamMedium_1.woff') format('woff');
}

@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 600;
  src: local('Gotham Medium'), url('fonts/GothamMediumItalic.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  src: local('Gotham Bold'), url('fonts/GothamBold.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  src: local('Gotham Bold'), url('fonts/Gotham-Bold.woff') format('woff');
}


@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 600;
  src: local('Gotham Bold Italic'), url('fonts/GothamBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Black Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Black Regular'), url('fonts/Gotham-Black.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Light Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light Regular'), url('fonts/Gotham-Light.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Thin Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Thin Regular'), url('fonts/Gotham-Thin.woff') format('woff');
}


@font-face {
  font-family: 'Gotham XLight Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham XLight Regular'), url('fonts/Gotham-XLight.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Book Italic'), url('fonts/Gotham-BookItalic.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Thin Italic'), url('fonts/Gotham-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Ultra Italic'), url('fonts/Gotham-UltraItalic.woff') format('woff');
}


@font-face {
  font-family: 'Gotham XLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham XLight Italic'), url('fonts/Gotham-XLightItalic.woff') format('woff');
}
*/

html, body, #root {
    background-color: #0CAAD0;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input, select {
    font-family: 'Montserrat', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
