@import "vars";

.mdc-select {
  display: inline-block;

  &__native-control {
    background-color: $primary-light-color;
    max-width: 110px;
    height: 39px;
    border: none;
    padding-left: 10px;
    margin-left: 5px;
    color: $primary-dark-color;
    font-size: 11px;
    font-weight: bold;

    option {
      padding: 10px 30px;
    }
  }
}

.myinvesment {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.myinvesment .card {
  background: $primary-dark-color !important;
  min-height: unset !important;
}

.total-balance {
  padding: 16px 32px;
  font-family: 'Nunito Sans', sans-serif;
}

.button-like-label {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 0 32px;
  color: #fff;
  min-width: 136px;
  background-color: #33B5E4;
  font-weight: 700;
}