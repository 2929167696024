@import "vars";
@import "mixins";
@import "button";
@import "~@material/layout-grid/mdc-layout-grid";
@import "~@material/menu-surface/dist/mdc.menu-surface.min.css";
@import "~@material/typography/dist/mdc.typography.min.css";
@import "~@material/chips/dist/mdc.chips.min.css";
@import "~@material/select/dist/mdc.select.min.css";
@import "~@material/menu/dist/mdc.menu.min.css";
@import "~@material/list/dist/mdc.list.min.css";
@import "~@material/slider/dist/mdc.slider.min.css";
@import "~@material/image-list/dist/mdc.image-list.min.css";
@import "~material-components-web/dist/material-components-web.min.css";
@import "table";
@import "withdraw";
@import "myinvestmensts";
@import "summary";
@import "materialui";

$footer-size: 64px;
$footer-upper-border-size: 2px;
$sidebar-size: 96px;
$sidebar-size-opened: 225px;
$sidebar-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
$card-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 7px rgba(0, 0, 0, 0.11);

$header-size: 88px;
$header-lower-border-size: 2px;


:root {
  --icon-button-color: #fff;
  --icon-button-active-color: $primary-dark-color;
  --mdc-theme-primary: #057E9D;
}

.mdc-dialog {

  /* &__surface {
     --mdc-theme-surface: #91D8F7
   }*/
}

.mdc-list {
  font-family: 'Raleway', sans-serif;
  font-size: .9rem;
}

.mdc-typography {
  --mdc-typography-font-family: 'Montserrat', sans-serif;
  font-family: 'Montserrat', sans-serif;

  &--subtitle1, &--subtitle2, &--body1, &--body2, &--caption, &--button, &--overline {
    font-family: 'Montserrat', sans-serif;
    //letter-spacing: .05rem;
  }

  &--headline1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.85rem;
    line-height: 6rem;
    font-weight: 600;
    letter-spacing: 0.25em
  }

  &--headline1, &--headline2, &--headline3, &--headline4, &--headline5, &--headline6 {
    font-family: 'Montserrat', sans-serif;
  }

  &--subtitle2, &--headline6 {
    font-weight: 600; // 500 doesn't do anything
  }

  &--body1 {
    font-size: .9rem;
  }

  &--body2 {
    font-size: .75rem;
  }

  &--caption {
    font-size: .65rem;
  }

  &--subtitle1 {
    font-size: .9rem;
  }

  &--headline5 {
    font-size: 1.2rem;
  }

  &--headline6 {
    font-size: 1.15rem;
    font-weight: 700;
    letter-spacing: .05rem;
  }

  &--numeric {
    font-family: 'Montserrat', sans-serif;
  }
}

.mdc-layout-grid {
  &--full-height {
    height: 100%;
  }

  &--big-spacing {
    // overwrite defaults
    --mdc-layout-grid-margin-desktop: 48px;
    --mdc-layout-grid-gutter-desktop: 48px;
    --mdc-layout-grid-margin-tablet: 16px;
    --mdc-layout-grid-gutter-tablet: 16px;
    --mdc-layout-grid-margin-phone: 16px;
    --mdc-layout-grid-gutter-phone: 16px;
  }

  &--big-gutter {
    // overwrite defaults
    --mdc-layout-grid-gutter-desktop: 48px;
  }
}

.app {
  height: 100%;
  color: $primary-text-on-dark-background;

  &__main-wrapper.opened {
    .app__main-sidebar {
      width: $sidebar-size-opened;

      .hidden {
        display: block;
      }

      .mdc-list-item--sub {
        height: 48px !important;
      }
    }

    .app__main {
      margin-left: $sidebar-size-opened;
    }
  }

  &__main-wrapper.opened + &__footer:after {
    width: $sidebar-size-opened;
  }

  .hidden {
    display: none;
  }

  &__main {
    height: 100%;
    background-color: $primary-color;
    margin-left: $sidebar-size;
    overflow: hidden;
    z-index: -1;
    transition: margin-left 200ms ease;

    // overwrite defaults
    --mdc-layout-grid-margin-desktop: 64px;
    --mdc-layout-grid-gutter-desktop: 24px;
    --mdc-layout-grid-margin-tablet: 16px;
    --mdc-layout-grid-gutter-tablet: 16px;
    --mdc-layout-grid-margin-phone: 16px;
    --mdc-layout-grid-gutter-phone: 16px;

    &-wrapper {
      height: calc(100% - #{$footer-size});
    }

    &-sidebar {
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      bottom: $footer-size - $footer-upper-border-size;
      width: $sidebar-size;
      background-image: linear-gradient(#f2f5f7, #fff);
      box-shadow: $sidebar-shadow;
      transition: width 200ms ease;

      .mdc-list {
        a {
          text-decoration: none;
        }

        .mdc-list-item {
          //overflow: visible;
          padding: 10px 25px 0;
          position: relative;

          &__graphic {
            padding-bottom: 16px;
          }

          &__text {
            //color: #fff;
            //padding-left: 8px;
            padding: 0 4px;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            text-align: center;
            color: $primary-light-color;
            font-size: .6875rem;
            line-height: 1;
            //font-weight: bold;
          }

          &--active {
            .mdc-list-item {
              &__graphic, &__text {
                color: #11A6CC;
              }
            }
          }

          /*&:hover {
            .mdc-list-item__text {
              position: absolute;
              left: 100%;
              display: block;
            }
          }*/
        }

        .mdc-list-item__graphic {
          margin-right: 0;
        }

        :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
          background-color: unset !important;
        }
      }

      &-header-cell {
        height: 56px;
      }

      .icon-button {
        //--icon-button-color: rgba(255, 255, 255, .7);
        --icon-button-color: #{$primary-light-color};

        &--primary {
          background-color: $primary-dark-color;
        }
      }
    }

    &-header {
      height: $header-size;
      border-bottom: $header-lower-border-size solid $primary-light-color;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      padding: 0 56px 24px 64px;

      &-name {
        text-transform: uppercase;
      }

      &__logo {
        flex-grow: 1;
        height: 56px;
        background: url("../images/Nekster_logoMono.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom left;
      }

      &__actions {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: -16px;

        .mdc-list {
          min-width: 240px;
        }
      }
    }

    &-content {
      min-height: calc(100% - #{$header-size});
      height: calc(100% - #{$header-size});
      max-height: calc(100% - #{$header-size});
      overflow-y: auto;
      overflow-x: hidden;
      background-color: $primary-color;

      .mdc-layout-grid:not(.mdc-layout-grid--big-spacing) {
        padding-top: 24px;
      }
    }
  }

  &__footer {
    z-index: 11;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: $footer-upper-border-size solid $primary-dark-color;
    box-sizing: border-box;
    background-color: $primary-color;
    height: $footer-size;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 16px;

    &-links {
      display: flex;
      align-items: center;

      > * {
        margin-right: 24px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      box-shadow: $sidebar-shadow;
      top: $footer-upper-border-size * -1;
      left: 0;
      height: $footer-upper-border-size;
      width: $sidebar-size;
      background-color: $primary-light-color;
    }

    &-connect {
      --mdc-layout-grid-margin-desktop: 7px;
      --mdc-layout-grid-margin-tablet: 7px;
      --mdc-layout-grid-margin-phone: 7px;

      --mdc-layout-grid-gutter-desktop: 0px;
      //--mdc-layout-grid-column-width-desktop: 72px;
      --mdc-layout-grid-gutter-tablet: 0px;
      //--mdc-layout-grid-column-width-tablet: 72px;
      --mdc-layout-grid-gutter-phone: 0px;
      //--mdc-layout-grid-column-width-phone: 72px;
    }
  }
}

.graph-switch {
  display: flex;
  align-items: stretch;

  &-wrapper {
    overflow-x: auto;
  }

  &-item {
    height: 64px;
    min-width: 200px;
    flex-basis: 25%;
    padding: 0 4px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &__content {
      height: 100%;
      box-sizing: border-box;
      background-color: #0a91b1;
      padding: 16px 16px 16px 64px;
      cursor: pointer;
      @include ripple();

      .icon-button {
        color: #fff;
      }
    }

    &__title {
      margin: 0;

    }

    &__text {
      line-height: 30px;
    }

    &--active &__content {
      background-color: $primary-dark-color;
    }
  }
}

.card {
  /*overflow: hidden;*/
  border-radius: 20px;
  min-height: 300px;
  background: $primary-color;
  position: relative;
  box-sizing: border-box;

  &__surface {
    border-radius: 20px;

    &--blurred {
      filter: blur(5px);
    }
  }

  // card flip
  &-scene {
    //perspective: 1920px;
  }

  &--flip {
    transition: transform 600ms;
    /*transform-style: preserve-3d;
    transform-origin: center right;*/
    position: relative;
    //overflow: hidden;
  }

  &--flip &__surface {
    overflow: hidden;
  }

  &--is-flipped {
    //transform: translateX(-100%) rotateY(-180deg);

    //transform: rotateY(-180deg);
  }

  &--is-flipped &__back {
    z-index: 1;
    display: flex;
  }

  &__back {
    display: none;
    flex-direction: column;

    &-scroll-content {
      overflow-y: auto;
    }

    .button {
      margin-left: -8px;
    }
  }

  &--is-flipped &__surface {
    overflow: hidden;
  }

  &--primary {
    background: $primary-dark-color;
  }

  &--fit {
    min-height: fit-content;
  }

  &--fill-parent {
    height: 100%;
  }

  &--unavailable {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-light-color
  }

  &--outlined {
    border: 1px solid #fff;
  }

  &--disabled {
    border-color: #57c4e6;
  }

  &--shaped {
    border-radius: 24px;
  }

  &--gradient {
    background: rgb(145, 216, 247);
    background: linear-gradient(300deg, #01aed8, #94d4ef 80%);
  }

  &--dark {
    background: $primary-dark-color;
  }

  &__over {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back {
    border-radius: 20px;
    background-color: $primary-dark-color;
    //backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //transform: rotateY(180deg);

    &-action {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      z-index: 10;
    }
  }

  &__header {
    padding: 16px;
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

  .graph-header {
    flex-direction: row;
    padding: 32px 32px 16px 32px;
  }

  &__title, &__headline {
    width: fit-content;
    margin: 0;
    //white-space: nowrap;
    position: relative;
  }

  &__headline {
    display: block;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__leading-text {
    line-height: 32px;
    padding: 0 16px;
    white-space: nowrap;


  }

  &__chip {
    background: $primary-darker-color;
    margin-left: -32px;
    padding: 2px 16px 2px 32px;

    span {
      font-weight: 300;
    }

    b {
      font-weight: 500;
    }
  }

  &__tag {
    position: absolute;
    right: 24px;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 32px 8px 8px 8px;
    align-items: center;
    background: $primary-dark-color;
    box-sizing: border-box;
    border: 1px solid $primary-dark-color;
  }

  &__content {
    margin: 0 32px 8px 32px;
    position: relative;
  }

  &__footer {
    display: flex;
    padding: 8px 16px 16px 16px;

    > .button {
      margin: 0;
    }
  }

  &--shaped &__footer {
    padding: 16px;
  }

  &__more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    //z-index: 10;

    &-icon {
      margin: 4px !important;
    }
  }

  &__info {
    width: 100%;

    &-btn {
      margin: 12px !important;
      width: 28px !important;
      height: 28px !important;
      --icon-button-color: #{$primary-dark-color};

      svg {
        width: 16px !important;
        height: 16px !important;
      }

      &:before {
        width: 20px !important;
        height: 20px !important;
      }

      &:after {
        width: 36px !important;
        height: 36px !important;
      }
    }
  }

  &--dark &__info-btn {
    --icon-button-color: #{$primary-light-color};
  }

  &__main {
    &--blurred {
      filter: blur(4px);
    }
  }

  &--expanded {
    min-height: 200px;
    margin: -48px -48px 0 -48px;
    border-radius: 0;
    background: $primary-light-color;

    .card__header {
      flex-direction: column;
      padding-bottom: 32px;
      padding-left: 48px;

      &-spacer {
        flex-grow: 1;
      }

      .card__leading-text {
        padding: 0;
      }
    }

    .card__surface {
      min-height: 112px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      //align-items: stretch;
      //justify-content: stretch;
    }

    .card__main {
      flex-grow: 1;
      padding: 16px 32px 0 48px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      //align-items: flex-end;
      justify-content: stretch;
    }

    .card__content {
      margin: 0 0px;
      flex-grow: 1;
      height: 135px;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      //max-width: 600px;
    }

    .card__footer {
      flex-grow: 1;
      width: 100%;
      max-width: 600px;
      padding-bottom: 32px;

      button {
        margin: 0;
      }
    }

    .card__chip {
      background: none;
      color: $primary-dark-color;
      padding-top: 0;
      padding-bottom: 0;
      height: 24px;

      b {
        text-transform: uppercase;
      }

      span {
        white-space: nowrap;
        font-size: .7rem;
        font-weight: 700;
      }
    }

    .card__leading-text {
      color: $primary-dark-color;

      span {
        font-size: 1.15rem;
      }

      &--primary {
        span {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.mdc-menu {

  &__title {
    font-weight: 500;
    color: #fff;
    font-size: .65rem;
    font-family: 'Montserrat', sans-serif;
    padding: 0 16px;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: .1em;
  }


  &-surface {
    background-color: $primary-dark-color;
    z-index: 11;


    .mdc-list {
      max-height: 400px;
    }

    .mdc-list-item, .mdc-list-item__text {
      color: #fff;
    }

    .mdc-list-item__primary-text {
      padding: 0;
      font-family: 'Titillium Web', sans-serif;
      letter-spacing: -.01rem;
      font-size: .9rem;
    }

    .mdc-list-item__secondary-text {
      color: $primary-light-color;
      font-size: .75rem;
      font-family: 'Raleway', sans-serif;
      letter-spacing: .05rem;
    }

    .mdc-list-divider {
      background-color: $primary-light-color;
      margin: 0 16px;
    }
  }
}

.strategy-card {
  min-height: 225px;
  transition: all 50ms ease-in-out;

  &:hover {
    background-color: $primary-dark-color-opacity;
  }

  &__header {
    padding: 16px 32px 8px;
    overflow: hidden;
    text-wrap: normal;
  }

  &__header, .button {
    transition: all 50ms ease-in-out;
  }

  &--selected {
    border-width: 2px;
    background-color: $primary-dark-color;
    box-shadow: $card-shadow;
    margin: -16px 0;

    .strategy-card__header {
      margin-top: 16px;
    }

    .button {
      margin-bottom: 16px;
    }

    .card__tag {
      border: 1px solid #fff;
    }

    .card__tag-no-tb {
      border-top: none
    }

    .list-item__icon .icon {
      color: #fff
    }

    .button {
      background-color: $green;
      //color: $primary-dark-color
    }
  }

  &--fill {
    height: 100%;
  }

  &__content {
    font-family: 'Titillium Web', sans-serif;
    min-height: 0;
    margin-left: 8px;

    .list-item {
      min-height: 0;
      cursor: default;
    }
  }

  &__stats {
    display: flex;
    padding: 16px 16px 0;
    cursor: default;
  }

  &__stat-item {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &-title, &-number {
      flex-basis: 100%;
    }

    &-title {
      text-align: right;
      text-transform: uppercase;
      font-size: .60rem;
      font-weight: 600;
      padding-right: 8px;
      line-height: 100%;
    }

    &-number {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 100%;
      margin-top: -2px;
      padding-right: 8px;
    }
  }
}


.mdc-tab {
  --mdc-theme-primary: #{$primary-light-color};
}

.surface {
  --icon-button-color: #{$primary-text-on-dark-background};
  background: $primary-dark-color;
  color: $primary-text-on-dark-background;
  margin: 8px;
  border-radius: 15px;

  &__title {
    text-transform: uppercase;
  }

  &--with-text {
    padding: 0 24px 8px 24px;
  }
}

.chart {
  //width: 100%;

  &-container {
    margin: 0 32px 32px;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__protection {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 25%;
      height: 25%;
      background: $primary-dark-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      //z-index: 20;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 24px 0 16px;

    &-color {
      display: inline-block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      background-color: currentColor;
    }

    &-item {
      padding-bottom: 8px;
      display: flex;
      align-items: center;
    }
  }

  &-tooltip {
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    flex-direction: column;
    background-color: transparent;


    &__value {
      display: block;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: .75rem;
      font-weight: 600;
    }

    &__label {
      display: block;
      color: #fff;;
      font-size: .625rem;
    }
  }
}

div.google-visualization-tooltip {
  background: rgba(0, 0, 0, 0) !important;
  border: none;
  box-shadow: none;
}

.button {
  display: block;
  background: transparent;
  border: none;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #fff;
  padding: 0 8px;
  margin: 8px 16px;
  font-size: .7rem;
  line-height: 36px;
  text-decoration: none;
  text-align: center;
  min-width: 64px;
  height: 36px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: .1em;
  border-radius: 4px;

  &[disabled] {
    cursor: default;
    //background-color: #cccccc;
    color: grayText;
    opacity: .9;
    pointer-events: none;
  }

  &--sized {
    min-width: 100px;
  }

  &--unelevated {
    padding: 0 16px;
    color: $primary-text-on-dark-background;
    background-color: $primary-darker-color;
  }

  &--primary {
    color: $primary-text-on-dark-background;
    background-color: $primary-dark-color;

    &[disabled] {
      color: $primary-hint-on-dark-background;
    }
  }

  &--green {
    color: $primary-text-on-dark-background;
    background-color: $green;
  }

  &--white {
    color: $primary-color;
    background-color: #fff;
  }

  &--prefix-icon &__icon {
    margin-right: 8px;
  }

  &--with-icon {
    padding-right: 40px !important;
  }

  &__icon {
    position: absolute;
    color: currentColor;
    display: inline-flex;
    height: 100%;
    align-items: center;
    right: 8px;

    .icon {
      display: flex;
      align-items: center;
    }
  }

  &--fill {
    width: 100%;
  }

  &--action {
    padding: 0 16px;
    background-color: $green;
    color: $primary-text-on-dark-background;
    font-weight: 700;
    border-radius: 4px;
  }

  &--warning {
    background-color: $red;
    color: $primary-text-on-dark-background;
  }

  &--submit-action {
    padding: 0 16px;
    background-color: #A6CE38;
    color: $primary-text-on-dark-background;
  }

  &--outlined {
    border: 2px solid #fff;
  }

  @include ripple();

  &--active {
    background: $primary-dark-color;
  }
}

b, strong {
  font-weight: 600;
}


.file-list {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    padding: 4px;
    border: 1px solid $primary-light-color;
    margin: 8px;

    &-photo {
      width: 120px;
      height: 100px;
      object-fit: contain;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-content {
      display: flex;
      align-items: center;
      border-top: 1px solid $primary-light-color;
      width: 120px;
    }

    &-name {
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}


.textfield {
  background: none;
  border: 0;
  outline: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  //color: var(--mdc-theme-text-primary-on-light)
}

.editable-textfield {
  display: inline;

  &__placeholder {

  }

  &__input {
    display: none;
    font-weight: 600;
    height: 56px;
    padding-left: 8px;
    max-width: calc(100% - 8px);
    margin: 0 -8px;
  }

  &--editable {
    .editable-textfield__placeholder {
      display: none;
    }

    .editable-textfield__input {
      display: inline;
    }
  }
}

.pie-graph {
  margin: 0 auto;
  position: relative;
  min-height: 315px;
  width: 315px;

  &-second {
    min-height: 264px;
    display: flex;
    justify-content: center;
    align-content: center;

    &-wrapper {
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;
    }
  }

  .chart__protection {
    height: 315px;
  }
}

.line-graph {
  margin: 16px 0;

  &__title {
    padding: 0 16px;
    font-size: .6875rem;
  }

  &__chart {
    min-height: 180px;
  }

  &__info {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    font-size: .625rem;
    border-top: 1px solid white;

    &--centered {
      > span {
        margin: 0 auto;
        text-align: center;
      }
    }

    > span {
      display: block;
      max-width: 500px;
    }
  }
}

.button-switch {
  width: 100%;
  display: flex;
  margin: 0;

  .button {
    flex-basis: 100%;
    margin-left: 0;
    border-radius: 0;
    margin-right: 1px;
    font-weight: 500;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:not(&--active) {
    .button--unelevated {
      background-color: $primary-light-color;
      color: $primary-dark-color
    }

    .button--active {
      background-color: #33B5E4;
      color: #fff;
    }
  }
}

.maximize-wrapper {
  width: $sidebar-size;
  height: $header-size;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  width: 48px;
  height: 48px;
  display: flex;
  box-sizing: border-box;
  border: none;
  color: var(--icon-button-color, $primary-text-on-dark-background);
  //margin: 8px 16px;
  position: relative;
  border-radius: 24px;
  padding: 12px;
  background: transparent;
  cursor: pointer;

  &:not(&--untouched) {
    svg {
      path, polygon {
        fill: currentColor;
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
  }

  &--outlined:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    top: 8px;
    left: 8px;
    right: unset;
    bottom: unset;
    box-sizing: border-box;
    border: 1px solid #fff;
  }

  &--right {
    float: right;
  }

  &--dense {
    width: 32px;
    height: 32px;
    margin: 8px;
    padding: 6px;
    border-radius: 16px;

    > svg {
      width: 20px;
      height: 20px;
    }

    &:before {
      width: 24px;
      height: 24px;
      top: 4px;
      left: 4px;
    }

    &:after {
      top: -4px;
      left: -4px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }

  &--primary {
    width: 28px;
    height: 28px;
    background-color: $primary-dark-color;
    border-radius: 0;
    padding: 6px;

    > svg {
      width: 16px;
      height: 16px;
    }

    &:before, &:after {
      width: 36px;
      height: 36px;
      border-radius: 0;
    }
  }

  &--shaped-circle:before {
    border-radius: 50%;
  }

  /*
    & + .icon-button {
      margin-left: 0;
    }*/

  &:hover:after {
    background: rgba(255, 255, 255, .1);
  }

  &:active:after, &:focus:after {
    transition: all .2s;
    background: rgba(255, 255, 255, .25);
  }

  &--active {
    color: $primary-dark-color
  }
}

.list {
  display: flex;
  flex-direction: row;

  &--dots > .list-item:before {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: $primary-dark-color;
    color: $primary-dark-color;
    margin-left: -16px;
  }

  &--center {
    align-items: center;
  }

  &--vertical {
    flex-direction: column;
  }

  &-item {
    box-sizing: border-box;
    padding: 0;
    min-height: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;

    &--prefix-icon {
      padding: 16px 24px 16px 16px;

      display: flex;
      align-items: center;
    }

    &--disabled {
      color: $primary-text-hover-on-light-background
    }

    &__icon {
      height: 100%;
      margin-right: 16px;
    }

    &--no-background {
      background-color: transparent !important;
    }

    &__text, &__other {
      padding: 0 8px;

      &:last-child {
        padding-left: 8px;
        padding-right: 24px;
      }

      &:first-child {
        padding-left: 24px;
        //padding-right: 8px;
      }
    }

    &__other {
      display: flex;
      align-items: center;
    }

    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: .825rem;
    }

    .button {
      margin: 0;
      min-width: 72px;
    }
  }

  .list-item--expand .list-item__text {
    white-space: unset;
    overflow: unset;
    padding-right: 0;
  }

  &--divider > .list-item, &--divider :not(.list-item) > .list-item {
    border-top: 1px solid $primary-light-color;
  }

  &--ordinal-background > .list-item, &--ordinal-background :not(.list-item) > .list-item {
    &:nth-child(odd) {
      background-color: #139cbf;
    }
  }

  /*> .list {
    padding: 0;
  }*/
}

.tutorial {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary-color;
  z-index: 502;
  padding: 8px 32px 16px 32px;
  max-height: 20vh;
  overflow: auto;
  height: 120px;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 500;
    display: none;

    &--active {
      display: block;
    }
  }

  &-exposed-element {
    z-index: 501;
  }

  &__actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }
}

.flex {
  display: flex;

  &--full-height {
    height: 100%;
  }

  &--max-vh {
    max-height: 100vh;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--space-between {
    justify-content: space-between;
  }

  > .button + .button {
    margin-left: 8px;
  }

  > .button--fill {
    width: unset;
    flex-grow: 1;
  }
}

.connect {
  display: flex;

  &__heading {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__actions {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    margin-left: 16px;
  }

  &__subtitle {
    display: block;
    padding-top: 2px;
    line-height: 1;
    margin-left: 16px;
  }
}

.banner {
  background: $primary-dark-color;
  display: flex;
  flex-direction: column;
  padding: 24px 48px 0 64px;
  box-sizing: border-box;

  &-background {
    background: $primary-dark-color;
  }

  &__row {
    display: flex;
    padding-bottom: 24px;
  }

  &__row + &__row {
    padding-bottom: 8px;
  }

  &--custom-light {
    //display: inline-block;
    width: 100% !important;
    background-color: $primary-light-color !important;
    color: $primary-dark-color
  }

  &__title {
    width: fit-content;
    padding: 8px 16px 8px 64px;
    //background: $primary-color;
    margin: 0 0 0 -64px;
    text-transform: uppercase;
    color: #fff;
    white-space: nowrap;

    /*  &-background {
        position: absolute;
        top: 0;
        left: -32px;
        right: 0;
        bottom: 0;
        background: $primary-color;
      }

      &-title {
        display: block;
        margin: 0;
        text-transform: uppercase;
      }*/
  }

  &__content {
    flex-grow: 1;
    padding-right: 32px;
    align-items: center;
    font-size: .8rem;
    display: flex;

    &-title {
      font-size: .825rem;
    }
  }

  &__title + &__content {
    padding-left: 32px;
  }

  .action-field {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &__graph {
    &-text {
      flex-grow: 1;
      //min-width: 220px;
    }

    &-title {
      display: block;
      white-space: nowrap;
      background: $primary-color;
      //width: fit-content;
      padding: 8px 24px;
    }

    &-data {
      display: inline-block;
      white-space: nowrap;
      width: fit-content;
      font-family: 'Titillium Web', sans-serif;
      padding: 12px 24px;
    }

    &-container {
      padding: 0 0 0 32px;
      flex-grow: 2;
      overflow: hidden;
    }
  }

}

.steps {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    position: absolute;
  }

  &__content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field__label {
    margin-bottom: 48px;
  }

  .mdc-select {
    width: 100%;
    max-width: 340px;
  }

  &__menu {
    width: 340px;
  }
}

.title {
  display: block;
  width: fit-content;
  //font-weight: 600;
  line-height: 2rem;
  min-height: 2rem;
  margin: .83rem 0;
  box-sizing: border-box;
  //border-bottom: 1px solid transparent;

  font-weight: 700;
  color: #fff;
  font-size: .95rem;
  font-family: 'Montserrat', sans-serif;
  //padding: 0 16px;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: .25em;


  &--border {
    //border-bottom: 1px solid $primary-light-color;
  }

  &-spacer {
    display: block;
    min-height: 24px;
  }

  &__wrapper {
    padding-bottom: 0;
    padding-right: 0;
  }
}

.subtitle {
  font-weight: 500;
  color: #fff;
  font-size: .95rem;
  font-family: 'Montserrat', sans-serif;
  padding: 0 16px;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: .1em;
}

.headline {

  &__title {
    font-weight: 700;
    color: #fff;
    font-size: .95rem;
    font-family: 'Montserrat', sans-serif;
    padding: 0 16px;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: .25em;
  }

  &__subtitle {
  }
}

.icon {
  width: 20px;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  display: inline-block;

  > svg {
    width: 16px;
    height: 16px;

    path, polygon {
      fill: currentColor;
    }
  }

  &--primary {
    color: $primary-dark-color
  }
}

.action-field {
  display: inline-flex;
  //width: -webkit-fill-available;
  //padding: 0 30px;
  margin: 0;
  justify-content: stretch;
  align-items: stretch;
  border-radius: 24px;
  background-color: #fff;
  color: $primary-text-on-light-background;
  overflow: hidden;

  /* &--light-table {
     background-color: #139cbf;
     color: #fff;
   }

   &--dark-table {
     background-color: #168ead;
     color: #fff;
   }*/

  &__content-wrapper {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  &--with-prefix {
    position: relative;
  }

  &__prefix {
    width: 48px;
    line-height: 48px;
    box-sizing: border-box;
    font-weight: bold;
    font-style: normal;
    color: inherit;
    min-width: 48px;
    text-align: center;
  }

  &__button {
    margin: 6px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }

  &__input {
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
    min-width: 100px;
    height: 48px;
    padding: 0 16px;
    outline: none;
    text-align: end;
    border: none;
    background-color: transparent;
    color: currentColor;
  }
}

::placeholder {
  color: currentColor;
  font-style: italic;
  padding: 0 2px;
}

input, ::placeholder {
  font-family: 'Titillium Web', sans-serif;
}

.form-field {
  display: flex;
  flex-direction: column;

  &--short {
    .form-field__label {
      margin: 0;
    }

    & + & {
      margin-top: 24px;
    }
  }


  &__label {
    margin: 8px 0 16px 0;
  }

  .custom-label {
    margin-bottom: 24px;
  }

  &__input {
    width: 100%;
    height: 40px;
    flex-grow: 1;
    background: none;
    padding: 0;
    box-sizing: border-box;
    color: $primary-text-on-dark-background;
    outline: none;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, .5);
    font-size: .96rem;
    font-weight: 400;
    text-indent: 4px;
    appearance: none;
    border-radius: 0;
    transition: all .1s;

    &::placeholder {
      color: $primary-hint-on-dark-background;
    }

    &:disabled {
      color: $primary-hint-on-dark-background;
    }

    &:hover, &:active, &:focus {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }

  &--light &__input {
    color: $primary-text-on-light-background;
    border-bottom: 2px solid rgba(0, 0, 0, .5);
  }
}

.mdc-chip-set {
  --mdc-theme-primary: #{$primary-dark-color};
}

.custom-form-layout {
  //padding-top: 0;
  //--mdc-layout-grid-margin-desktop: 32px;
  --mdc-layout-grid-gutter-desktop: 72px;
  //--mdc-layout-grid-margin-tablet: 16px;
  //--mdc-layout-grid-gutter-tablet: 16px;
  //--mdc-layout-grid-margin-phone: 16px;
  //--mdc-layout-grid-gutter-phone: 16px;

  .form-field {
    margin-bottom: 32px;
    //margin-bottom: 48px;
  }

  .button {
    margin: 0;
  }
}

.mdc-switch {
  --mdc-theme-secondary: $primary-light-color;
}

.custom-account-list-item {
  flex-basis: 100%;
  letter-spacing: unset;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;

  &--with-icon {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  &--adjust-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 56px;
  }

  > .custom-account-list-item--adjust-end {
    padding-right: 0;
  }

  &:last-of-type {
    //
  }

  &__ranking {
    background: $green;
    padding: 0 4px;
  }

  .form-field {
    flex-grow: 1;
    margin-left: -16px;
    margin-right: -16px;

    .mdc-select__selected-text {
      font-weight: 500;
      text-transform: none;
    }
  }

  .mdc-chip-set {
    margin-left: -8px;
  }
}

.custom-page-dark-background {
  background-color: $primary-dark-color;
  height: 100%;
}

.mdc-image-list {
  &__item {
    width: 120px;
    height: 120px;
  }
}

.mdc-chip {
  font-family: inherit;
  color: $primary-text-on-dark-background;
  background-color: $primary-dark-color;
  user-select: none;

  path {
    stroke: $primary-text-on-dark-background;
  }

  &:hover {
    color: $primary-text-on-dark-background;
  }
}

.mdc-select {
  border-bottom: 2px solid rgba(255, 255, 255, .5);
  box-sizing: border-box;
  height: 40px;
  border-radius: 0 !important;

  &--focused &__dropdown-icon {
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23057E9D%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center
  }

  &--invalid {
    border-color: $red;
  }

  &--no-border {
    border-bottom: 0;
  }

  &--unelevated {
    border-bottom: 0;
    background-color: $primary-light-color !important;
  }

  &--next:not(&--invalid) &__dropdown-icon {
    background-color: $primary-light-color;
    transform: rotate(270deg);
    pointer-events: all;
    cursor: pointer;
  }

  &--activated &__dropdown-icon {
    background-color: transparent;
    transform: rotate(180deg) translateY(-5px);
    pointer-events: none;
  }

  &__selected-text {
    //height: 39px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 40px;
    line-height: 40px;
    color: $primary-text-on-dark-background !important;
    //line-height: 39px;
    font-family: 'Titillium Web', sans-serif;
    min-width: 142px;
    text-align: right;
    font-weight: 500;
    font-size: .9rem;
    letter-spacing: unset;
  }

  &--unelevated > &__selected-text {
    background-color: $primary-light-color !important;
    color: $primary-text-on-light-background !important;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__dropdown-icon {
    bottom: 8px;
    color: $primary-text-on-light-background !important;
  }

  &__menu {
    border-radius: 0;

    .mdc-list, .mdc-list-item {
      background-color: $primary-dark-color;
      color: $primary-text-on-dark-background !important;
    }

    .mdc-list-item {

      font-family: 'Titillium Web', sans-serif;
      /*margin-left: 12px;
      margin-right: 12px;*/
      padding: 0 16px;
      min-width: 120px;
      box-sizing: border-box;
      height: 40px;
      font-size: .9rem;

      &:after, &:before {
        content: unset !important;
      }

      &:hover {
        background-color: #068bad;
      }
    }

    .mdc-list-divider {
      background-color: $primary-light-color;
      margin: 0 16px;
    }
  }
}

.text-field {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 340px;

  &__input {
    width: 100%;
    height: 40px;
    flex-grow: 1;
    background: none;
    padding: 0;
    box-sizing: border-box;
    color: $primary-text-on-dark-background;
    outline: none;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, .5);
    font-size: .96rem;
    font-weight: 400;
    text-indent: 4px;
    appearance: none;
    border-radius: 0;
    transition: all .1s;

    &::placeholder {
      color: $primary-hint-on-dark-background;
    }

    &:disabled {
      color: $primary-hint-on-dark-background;
    }

    &:hover, &:active, &:focus {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }

  &__next-icon {
    display: none;
    left: auto;
    right: 8px;
    position: absolute;
    width: 24px;
    height: 24px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23057E9D%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
    background-color: #91D8F7;
    transform: rotate(270deg);
    pointer-events: all;
    cursor: pointer;
    bottom: 10px;
    color: #057E9D !important;
  }

  &__next-icon-auto-complete {
    display: none;
    left: auto;
    right: 0px;
    position: absolute;
    width: 24px;
    height: 24px;
    pointer-events: none;
    background-color: #91D8F7;
    pointer-events: all;
    cursor: pointer;
    bottom: 0px;
    color: #057E9D !important;
  }

  &--next:not(&--invalid) &__next-icon {
    display: block;
    background-color: $primary-light-color;
    transform: rotate(270deg);
    pointer-events: all;
    cursor: pointer;
  }

  &__input[disabled] + .text-field__next-icon {
    pointer-events: none;
  }
}

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: $primary-color;
  color: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mdc-slider {
  margin-top: 14px;

  &-wrapper {
    position: relative;
  }

  &__pin {
    background-color: $primary-dark-color !important;
  }

  &__thumb {
    fill: $primary-dark-color !important;
    stroke: $primary-dark-color !important;
  }

  &__track-container {
    background-color: #fff !important;
    height: 2px !important;
  }

  &__range {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    color: #fff;
    transition: all 200ms;
  }

  &-pair {
    position: relative;

    &__slider:nth-child(2) {
      position: absolute;
      top: 0;
      margin-top: 0;
      //display: none;

      .mdc-slider__track {
        background-color: $primary-dark-color
      }
    }

    &__slider:nth-child(1) {
      .mdc-slider__thumb-container {
        z-index: 2;
      }

      .mdc-slider__track {
        background-color: #fff;
        z-index: 1;

        &-container {
          background-color: #fff;
        }
      }
    }

    &__slider.mdc-slider--active ~ .mdc-slider__range {
      opacity: 0;
    }
  }
}

.graph-banner {
  display: flex;

  &-grid-adjust {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__item {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-wrap: nowrap;
    margin-right: 32px;
    align-items: center;

    &-section {
      //flex-basis: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      max-height: 150px;
    }
  }

  &__item + &__item {
    margin-right: 0;
    margin-left: 32px;
  }
}

.mdc-list--nav {
  //background-color: $primary-light-color;
  z-index: 10;
}

.mdc-list-item {
  //overflow: unset;

  &--sub {
    padding-top: 0 !important;
    height: 0 !important;
    transition: height 200ms;

    > .mdc-list-item__text {
      margin-left: 48px;
    }
  }

  &--reveal {

    > .mdc-list-item__text {
      display: none;
      //overflow: unset;
      position: absolute;
      left: 100%;
      padding: 8px 16px !important;
      background-color: $primary-dark-color;
      transform: translateX(-100%);
      transition: 200ms;
      z-index: -10;
    }

    &:hover {
      > .mdc-list-item__text {
        transform: translateX(0);
      }
    }
  }
}

.mdc-layout-grid-logo-container {
  position: absolute;
  bottom: 0;
  width: 100%;

  .logoWrapper {
    background-image: url("../images/Nekster_logoBlue.png");
    background-repeat: no-repeat;
    background-size: 35px;
    min-height: 150px;
  }
}

.bold {
  font-weight: 600;
}

.custom-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: calc(100vh - #{$header-size} - #{$footer-size});

  &-wrapper {
    width: 32px;
    height: 100%;
    top: 0;
    position: absolute;
    right: 0;
    cursor: pointer;
    background-color: $green;
    color: $primary-text-on-light-background;

  }

  &__text {
    flex-basis: 100%;
    flex-grow: 1;
    padding: 16px 0;
    //transform: rotate(-180deg);
    //margin: 0 auto;
    box-sizing: border-box;


    span {
      display: block;
      white-space: nowrap;
      font-size: .85rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .05rem;
      text-align: end;
      writing-mode: tb-rl;
    }
  }

  &__icon {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: center;
    width: 15px;
    color: $primary-text-on-light-background;

    svg {
      fill: currentColor;
    }
  }
}

.mdc-tab-bar {
  background-color: $primary-dark-color;
  position: sticky;
  bottom: 0;
}

.view {
  display: none;

  [active] {
    display: block;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

.show-on-mobile {
  display: none;
}

.table {
  &-responsive {
    &--adjusted {
      width: 100%;
    }
  }
}

.feed {
  &-table {

    tr {
      height: 36px;
      border-bottom: 1px solid $primary-light-color;

      td {
        font-size: .825rem;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    tr:nth-child(even) {
      background-color: unset;
    }
  }
}

@media (max-width: 1024px) and (min-width: 480px), (max-width: 1024px) and (orientation: landscape) and (min-width: 480px) {
  .mdc-layout-grid {
    --mdc-layout-grid-margin-desktop: 24px;
  }

  .app__footer {
    &:after {
      width: 72px;
    }

    &-connect {
      display: none;
    }
  }

  .app__main-header {
    padding: 0 16px 16px 16px;
  }
  .app__main {
    margin-left: 72px;

    &-header {
      height: 72px;
      padding: 8px 16px 16px 24px;
    }

    &-content {
      min-height: calc(100% - 72px);
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
    }
  }

  .app__main-header__actions {
    //margin-bottom: 0;
  }
  .app__main-sidebar {
    //right: 0;
    //top: unset;
    width: 72px;
    //bottom: 0;
    //height: 56px;
    //display: none;

    .mdc-layout-grid-logo-container {
      padding: 0 16px;
    }

    .mdc-list {
      padding-top: 16px;

      .mdc-list-item {
        padding: 0 12px;
      }
    }
  }

  .card {
    .graph-header {
      padding: 32px 32px 16px 16px;
      flex-direction: column;
    }

    &__leading-text {
      padding: 0;
    }

    &__content {
      margin: 0 16px 8px 16px;
    }

    &__footer {
      padding: 8px 8px 8px 8px;
    }

    &__chip {
      margin-left: -16px;
      padding: 2px 16px 2px 16px;
    }

    .button-switch {
      flex-direction: column;
      margin: 0 8px 8px 8px;

      > .button {
        margin: 0;
      }
    }
  }

  /*.table {
    td, th {
      padding: 0 4px;
    }

    .button {
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }*/

  .chart-container {
    margin: 0;
  }

  .table-responsive {
    /*width: calc(100% + 32px);
    margin: 0 -16px;*/
    overflow-y: hidden;
    min-height: .01%;
    overflow-x: auto;

    tr {
      padding: 8px 16px;
    }
  }

  .banner {
    padding-left: 16px;
    padding-right: 16px;

    .action-field {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;

      &__button {
        margin-right: 6px !important;
      }
    }
  }

  .banner__row {
    flex-direction: column;

    .button {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    /*+ .banner__row {
      display: none;
    }*/
  }

  .banner__row--with-action {
    padding-bottom: 0;

    .action-field {
      width: 100%;
    }
  }

  .banner__content {
    padding: 16px 0 !important;
  }

  .graph-banner {
    overflow: hidden;

    &__item {
      max-width: 100%;
      margin-right: 0;

      &-section {
        overflow: hidden;

        .total-balance {
          padding: 16px 0;
        }
      }
    }
  }

  .list-item {

    .form-field {
      margin-left: -24px;
      //margin-right: 0;
    }
  }
}

@media (max-width: 479px), (max-width: 479px) and (orientation: landscape) {
  .app__main-header {
    padding: 0 16px 16px 16px;
  }
  .app__main {
    margin-left: 0;
  }
  .app__main-wrapper {
    height: calc(100% - 56px);
  }
  .app__main-header {
    //height: 72px;
    padding: 8px 16px 16px 24px;
  }
  .app__main-header__actions {
    //margin-bottom: 0;
  }
  .app__main-sidebar {
    right: 0;
    top: unset;
    width: 100vw;
    bottom: 0;
    height: 56px;
    //display: none;

    .maximize-wrapper, .mdc-layout-grid-logo-container {
      display: none;
    }

    .mdc-list {
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0;

      .mdc-list-item {
        flex-basis: 100%;
        height: 56px;
        flex-grow: 1;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .app__footer {
    display: none;
  }
  .custom-navigation-wrapper {
    display: none;
  }
  .card {
    min-height: 112px;
  }
  .card__surface {
    min-height: 112px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    //align-items: stretch;
    //justify-content: stretch;

    > * {
      flex-basis: 100%;
    }

    .card__content {
      margin: 24px 24px 0 0;
      overflow: hidden;
    }

  }
  .card .graph-header {
    flex-direction: column;
    padding: 24px 24px 24px 0;
  }
  .card__chip {
    margin-left: 0;
    padding-left: 16px;
  }
  .card__main {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .card__content {
    margin: 0 16px 8px 16px;
  }
  .card__footer {
    padding: 8px 8px 8px 8px;
  }
  .card--expanded {
    margin: -16px -16px 0 -16px;
    border-radius: 0;
    background: $primary-light-color;

    .card__content {
      margin: 0px 24px 0 0;
    }

    .card__chip {
      background: none;
      color: $primary-dark-color;
      padding-top: 0;
      padding-bottom: 0;
      height: 24px;

      b {
        text-transform: uppercase;
        font-size: .7rem;
        font-weight: 700;
      }
    }

    .card__leading-text {
      color: $primary-dark-color;

      span {
        font-size: 1.15rem;
      }

    }
  }
  .button-switch {
    margin: 0 8px 0 8px;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .show-on-mobile {
    display: block;
  }
  .table {
    td, th {
      padding: 8px 8px;
    }

    .button {
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .chart-container {
    margin: 0;
  }

  .table-responsive {
    width: calc(100% + 32px);
    margin: 0 -16px;
    overflow-y: hidden;
    min-height: .01%;
    overflow-x: auto;

    tr {
      padding: 8px 16px;
    }
  }

  .banner {
    padding-left: 16px;
    padding-right: 16px;

    .action-field {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;

      &__button {
        margin-right: 6px !important;
      }
    }
  }

  .banner__row {
    flex-direction: column;

    .button {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    /*+ .banner__row {
      display: none;
    }*/
  }

  .banner__row--with-action {
    padding-bottom: 0;

    .action-field {
      width: 100%;
    }
  }

  .banner__content {
    padding: 16px 0 !important;
  }

  .graph-banner {
    overflow: hidden;

    &__item {
      max-width: 100%;
      margin-right: 0;

      &-section {
        overflow: hidden;

        .total-balance {
          padding: 16px 0;
        }
      }
    }
  }

  .list-item {

    .form-field {
      margin-left: -24px;
      //margin-right: 0;
    }
  }

  .display-container {
    width: 100%;

    > .show-on-mobile {
      width: 100%;
    }
  }

  .page {
    width: 100%;
    overflow-y: hidden;

    &.not-active {
      display: none;
    }

    &--next.active {
      transform: none;
      display: block;
    }

    &__inner {
      overflow: hidden;
      width: 100%;
    }
  }

  .display-container--mobile {
    width: 100%;
  }
}

.dropbox {
  margin: 16px;
  height: 150px;
  width: 150px;
  box-sizing: border-box;
  border: 2px dashed rgba(255, 255, 255, .4);
  cursor: pointer;
  position: relative;
  transition: all 150ms ease-out;

  &:hover {
    border-color: rgba(255, 255, 255, .7);
  }

  &--highlight {
    border-color: #fff;
    transform: scale(1.1);
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__upload {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: .75rem;
    position: relative;
    pointer-events: none;

    svg {
      width: 32px;
      height: 32px;
    }

    > img {
      height: 72px;
      width: 72px;
      margin-bottom: 24px;
      object-fit: contain;
    }

    > span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      padding: 8px;
    }

    svg.dropbox__upload-success {
      width: 24px;
      height: 24px;
    }
  }
}

.layout {
  &-upload-documents {
    //width: 600px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
}

.text-field-form-row {
  display: flex;

  > *:first-child {
    margin-right: 16px;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.MuiDialogContent-root {
  .MuiInputLabel-formControl {
    color: black !important;
  }

  .MuiOutlinedInput-input {
    color: black !important;
  }
}

[hidden] {
  display: none;
}

