@import "vars";

.summary-table {
  tr td:nth-child(1) {
    width: 50px;
  }

  tr td:nth-child(3) {
    text-align: end;
    padding-right: 20px;
  }

  .new {
    background-color: red;
    padding: 5px 10px;
    font-size: 12px;
    position: relative;
    left: -25px;

  }
}