@import "vars";

.table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  //margin: 0 30px;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;

  &-responsive {
    width: 100%;
    /*margin: 0 -16px;*/
    overflow-y: hidden;
    min-height: .01%;
    overflow-x: auto;
  }

  &-fixed {
    width: 100%;
    /*margin: 0 -16px;*/
    overflow-y: hidden;
    min-height: .01%;
    overflow-x: auto;
  }

  &-header {
    display: flex;
    align-items: center;
    padding: .83rem 0;

    &__title {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-text {
        display: inline !important;
      }
    }

    &__sort {

    }
  }

  .stretch {
    width: 100%;
  }

  .flex {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .cell-icon {
    max-width: 24px;
    padding-right: 0;
  }

  .cell-flag {
    padding-right: 0;

    img {
      display: block;
      max-height: 20px;
      //object-fit: contain;
    }
  }

  .fit {
    width: fit-content;
    max-width: 60px;
  }

  .bullet {
    text-align: center;
    padding-right: 0;
    max-width: 24px;

    &:before {
      content: "•";
      position: relative;
      height: 24px;
      width: 32px;
      display: block;
      font-size: 24px;
      //top: -22px;
    }

    &.red:before {
      color: #a90000;
    }

    &.green:before {
      color: $green;
    }

    &.white:before {
      color: #fff;
    }

    &.orange:before {
      color: #ffe160;
    }
  }

  /*.done td:nth-child(2), .done td:last-child, .failed td:nth-child(2), .failed td:last-child {
    font-weight: bold;
  }*/

  th {
    cursor: default;
  }

  th:not(.no-sort) {
    cursor: pointer;
  }

  th[role=columnheader]:not(.no-sort):after {
    content: '';
    border-width: 4px 4px 0;
    border-style: solid;
    border-color: #fff transparent;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    display: inline-flex;
    vertical-align: middle;
    margin-left: 9px;
    cursor: pointer;
  }

  th[aria-sort=ascending]:not(.no-sort):after {
    border-color: #fff transparent;
    border-width: 0 4px 4px;
    cursor: pointer;
  }

  th {
    font-weight: normal;
  }

  tr {
    height: 48px;
  }

  tr:nth-child(even) {
    background: #139cbf;
  }

  &--dark {
    tr:nth-child(even) {
      background: #168ead;
    }
  }

  td, th {
    //white-space: nowrap;
    padding: 8px 16px;
    box-sizing: border-box;
  }

  &--no-wrap td, &--no-wrap th {
    white-space: nowrap;
  }

  tr td {
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: -.01rem;

    &:first-of-type {
      padding-left: 1rem;
    }

    &:last-of-type {
      padding-right: 1rem;
    }
  }

  &__head-row {
    font-family: 'Montserrat', sans-serif;
    color: var(--mdc-theme-text-secondary-on-dark)
  }

  td > svg {
    margin: 0 8px;
    width: 20px;
    height: 20px;
    position: relative;
    vertical-align: middle;
  }

  .button-invest {
    /*background: #fff;
    color: $primary-text-on-light-background;*/
    height: 28px;
    line-height: 28px;
    width: fit-content;

    &--green {
      background-color: #A6CE38;
      color: $primary-text-on-dark-background;
    }
  }

  &__total {
    border-top: 2px solid #fff;
  }
}

td[colspan]:not([colspan="1"]) {
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.button-containter {
  width: 100%;
}

u {
  text-decoration: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
}

.button-deposit, .button-withdraw {
  float: right;
  background: $primary-dark-color !important;
  color: white !important;
  font-weight: bolder !important;
  min-width: 120px !important;
  margin: 0 30px !important;
  font-size: 0.8em !important;

}

.sort-header {
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  cursor: pointer;

  &:hover, &--asc, &--desc {
    color: #fff;
  }

  &__text {
    display: flex;
    align-items: center;
  }

  &__icon {
    position: relative;
    min-width: 16px;
    display: inline-flex;
    //align-items: center;
  }

  &__icon-asc, &__icon-desc {
    margin: 0 0 0 5px;
    font-size: 16px !important;
    min-width: 16px !important;
    //position: absolute !important;
    display: none !important;
  }

  &--asc &__icon-asc, &--desc &__icon-desc {
    display: inline-block !important;
  }

  &--unsorted &__icon-asc {
    display: inline-block !important;
    visibility: hidden;
  }

  &--unsorted:hover &__icon-asc {
    display: inline-block !important;
    color: var(--mdc-theme-text-secondary-on-dark);
    visibility: visible;
  }
}
