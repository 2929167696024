@import "vars";

.button-withdraw {
  background-color: #A6CE38 !important;
}

.mdc-layout-grid {
  &__dark {
    background-color: $primary-dark-color !important;
    padding: 32px 64px;
  }

  &__light {
    background-color: $primary-light-color !important;
    padding: 32px 64px;
  }
}

.mdc-typography--dark-color {
  color: $primary-dark-color;
}

.table.withdraw, .table.withdraw-table{
  tr:nth-child(even) {
    background: $primary-light-color;
  }
}

.icon-currency.withdraw {
  background: $primary-light-color;
}