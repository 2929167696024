/* MATERIAL UI LIBRARY */

/* PAPER/OVERLAY GLOBAL CSS */
.MuiPaper {
  
  &-rounded {
    border-radius: 0 0 4px 4px!important;
  }
}

/* AUTOCOMPLETE GLOBAL CSS */
.MuiAutocomplete {

  &-paper {
    background-color: $primary-dark-color !important;
    color: $primary-text-on-dark-background !important;
  }

  &-inputRoot {
    color: $primary-text-on-dark-background !important;
    &:before {
      border-bottom: 1px solid   $primary-text-on-dark-background !important;
    }
    &:after {
        border-bottom: 2px solid  $primary-text-on-dark-background !important;
    }
  }

  &-option {
    border-bottom: 1px solid;
    font-family: 'Titillium Web', sans-serif !important;
    font-size: .9rem !important;
    min-height: 40px !important;
    &:hover {
      background-color: #068bad !important;
    }
    &[data-focus="true"] {
      background-color: #068bad !important;
    }
  }

  &-noOptions {
    font-family: 'Titillium Web', sans-serif !important;
    font-size: .9rem !important;
    color: $primary-text-on-dark-background !important;
  }

  &-clearIndicator {
    color: white !important;
  }

  &-popupIndicator {
    color: white !important;
    background-color: $primary-dark-color !important;
  }

  &-listbox {
    max-height: 25vh !important;
  }
}

/* INPUT */

// Barva Labele na input polju
.MuiFormLabel-root {
  color: white !important;
}

.MuiInput {

  &Base-input {
    color: white !important; //Barva Pisave na input polju
  }
  &-underline:after {
    border-bottom-color: white !important; //Barva bottom borderja
  }
  &-underline:before {
    border-bottom-color: white !important;
  }
}

/* Izpis Warning Besedila */
.MuiFormHelperText-root {
  color: white !important;
}