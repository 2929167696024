@import "vars";

$num-of-steps: 12;
$max-row-width: 288px;

:root {
  --content-width: $content-max-width;
}

@mixin containerChild() {
  margin: 0 auto;
  max-width: var(--content-width, $content-max-width);
  padding: 0 $content-padding;
}

@mixin containerFullWidth() {
  //width: calc(100vw + #{$content-padding});
  //width: calc(100vw - #{$content-padding} * 2);
  margin-left: $content-padding * -1;
  margin-right: $content-padding * -1;
}

.Container {
  @include containerChild();

  &--Stretch {
    //width: 100%;
    width: calc(100% - #{$content-padding} * 2);
  }
}

.Register {
  $this: &;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 200px auto 48px;
  min-height: 100vh;
  text-align: center;

  a {
    text-decoration: none;
  }

  &-Password-Meter {
    font-size: 12px;

    &-State {
      font-weight: 500;
    }
  }

  &-Hint {
    font-size: 10px;
    text-align: left;
    margin: 0;
    padding-left: 16px;
  }

  &-Row {
    width: 100%;
    display: flex;
    max-width: $max-row-width;
    flex-wrap: nowrap;
    //overflow: hidden;
    margin-bottom: 16px;
    position: relative;

    &-Spacing {
      min-height: 64px;
    }

    > * {
      margin-right: 8px;
      //overflow: hidden;
    }

    > *:last-child {
      margin-right: 0;
    }

    &--1-1 {
      //overflow-x: hidden;

      > *:first-child {
        flex-basis: 50%;
        flex-grow: 1;
        flex-shrink: 1;
        max-width: calc(50% - 4px);
      }

      > *:last-child {
        flex-basis: 50%;
        flex-grow: 1;
        flex-shrink: 1;
        max-width: calc(50% - 4px);
      }
    }

    &--1-3 {
      //overflow-x: hidden;

      > *:first-child {
        flex-basis: 33.3%;
        flex-grow: 1;
        flex-shrink: 1;
        max-width: calc(33.3% - 4px);
      }

      > *:last-child {
        flex-basis: 66.6%;
        flex-grow: 2;
        flex-shrink: 2;
        max-width: calc(66.6% - 4px);
      }
    }

    &--3-1 {
      //overflow-x: hidden;

      > *:first-child {
        flex-basis: 66.6%;
        max-width: calc(66.6% - 4px);
        flex-grow: 2;
        flex-shrink: 2;
      }

      > *:last-child {
        flex-basis: 33.3%;
        max-width: calc(33.3% - 4px);
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  &-Row + &-Row:not(&-Row--No-Margin-Top) {
    margin-top: 16px;
  }

  &-Action {
    margin: 48px 0 0 0
  }

  &-FormField {
    max-width: $max-row-width;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    min-height: 32px;

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $primary-color inset !important;
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
      -webkit-text-fill-color: $primary-text-on-dark-background !important;
    }

    &--Date {
      display: flex;
      flex-direction: row;
      position: relative;

      > input {
        flex-basis: 33.33%;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &--Code {
      flex-direction: row;
    }

    &__Datepicker {
      margin: 0 !important;
      border: none;
      padding: 0;
      max-width: 0;
      visibility: collapse;
    }

    &--Horizontal {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: nowrap;
    }

    #{$this}-Input {
      width: 100%;
      height: 32px;
      flex-grow: 1;
      background: none;
      padding: 0;
      box-sizing: border-box;
      color: $primary-text-on-dark-background;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, .5);
      font-size: .96rem;
      font-weight: 400;
      text-indent: 4px;
      appearance: none;
      border-radius: 0;

      &::placeholder {
        color: $primary-hint-on-dark-background;
      }

      &:disabled {
        color: $primary-hint-on-dark-background;
      }

      &--Invalid {
        border-bottom: 1px solid red;
      }

      &--Warning {
        border-bottom: 1px solid orange;
      }

      &--Success {
        border-bottom: 1px solid greenyellow;
      }

      &--Code {
        font-size: 18px;
        flex-basis: 100%;
        margin-right: 8px;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: center;

        &:nth-last-child(2) {
          margin-right: 0;
        }

        &-Master {
          position: absolute;
          border: none;
          color: transparent;
          opacity: 0;
        }

        /*font-size: 16px;
        letter-spacing: 48px;
        border: 0;
        position: absolute;
        left: 0;
        width: $max-row-width;
        max-width: 100%;
        text-indent: 16px;

        &-Grid {
          height: 1px;
          position: absolute;
          bottom: 0;
          background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 32px, rgba(255, 255, 255, 0) 0%);
          background-position: bottom;
          background-size: calc(calc(100% / 6) + 2.6667px) 1px;
          background-repeat: repeat-x;
          background-position-x: 0;
          width: $max-row-width;
        }*/
      }

      &-PX {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px;
        width: 20px;
        height: 32px;
        position: absolute;
        left: 4px;
        top: 0;
        bottom: 0;

        &-Input {
          padding-left: 28px;
        }

        &--Date {
          cursor: pointer;
          background-image: url("../images/baseline-calendar_today-24px.svg");

        }
      }
    }

    #{$this}-Checkbox {
      position: relative;
      overflow: hidden;
      width: 24px;
      height: 24px;
      min-width: 24px;

      &-Icon {
        background-image: url("../images/outline-check_box_outline_blank-24px.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        cursor: pointer;
      }

      > input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        background: none !important;
        border: none !important;
        bottom: 0;
        opacity: 0;
        overflow: hidden;
        left: 9px;

        &:checked + #{$this}-Checkbox-Icon {
          background-image: url("../images/outline-check_box-24px.svg");
        }
      }

      &-Text {
        font-size: .7rem;
        padding: 5px 4px;
        text-align: justify;

        a {
          box-sizing: border-box;
          border-bottom: 1px dotted;
          cursor: pointer;
        }
      }

    }

    #{$this}-Select {
      height: 32px;
      flex-grow: 1;
      background: none;
      padding: 0;
      box-sizing: border-box;
      color: $primary-text-on-dark-background;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, .5);
      font-size: .96rem;
      font-weight: 400;
      text-indent: 4px;
      appearance: none;
      border-radius: 0;

      option {
        color: black;
      }

      &-Icon {
        background-image: url("../images/baseline-arrow_drop_down-24px.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px 14px;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 2px;
        top: 9px;
        bottom: 0;
        display: block;
        pointer-events: none;
        box-sizing: border-box;
        //display: none;

        &--Show {
          pointer-events: all;
          background-image: url("../images/baseline-visibility-24px.svg");
        }

        &--Hide {
          pointer-events: all;
          background-image: url("../images/baseline-visibility_off-24px.svg");
        }
      }

      &:required:invalid {
        color: $primary-hint-on-dark-background;
      }

    }


    #{$this}-Datalist {
      position: relative;
      height: 32px;
      flex-grow: 1;

      &-Dropdown {
        display: none;
        position: absolute;
        margin: -1px 0 0 0;
        padding: 0;
        width: 100%;
        max-height: 200px;
        top: 100%;
        left: 0;
        list-style: none;
        border-radius: 0;
        background: $primary-dark-color;
        overflow: hidden;
        overflow-y: auto;
        z-index: 100;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        li {
          display: block;
          text-align: left;
          font-size: 12px;
          line-height: 20px;
          min-height: 20px;
          margin: 0 10px;
          border-bottom: 1px solid $primary-light-color;
          padding: 10px 0;
          box-sizing: border-box;
          color: $primary-text-on-dark-background;
          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      &--Active #{$this}-Datalist-Dropdown {
        display: block;
      }
    }


    &[data-error="true"] #{$this}-Input, &[data-error="true"] #{$this}-Select {
      border-bottom: 1px solid red;
    }

    &[data-error="true"] #{$this}-FormField-Helper {
      color: red
    }

    &--Next {
      #{$this}-Select-Icon {
        //display: none;
      }

      .Button--Next {
        display: block;
      }
    }

    &-Helper {
      position: absolute;
      top: 100%;
      font-weight: 300;
      font-size: .7rem;
      align-self: flex-start;
      text-align: left;
      padding: 4px 0;

      &:empty {
        display: none;
      }

      &--Error {
        color: red;
      }

      &--Dropdown {
        margin-top: -1px;
        background-color: $primary-dark-color;
        padding: 8px;
        color: $primary-text-on-dark-background;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }

  &-EditField {
    max-width: $max-row-width;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-Value {
      font-size: .8rem;
      flex-grow: 1;
      text-align: start;
    }

    &-Icon {
      background-image: url("../images/baseline-edit-24px.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 12px;
      background-color: #fff;
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  &-Header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /*border-bottom: 1px solid $primary-dark-color;*/

    &-Wrapper {
      background-color: $primary-color;
    }

    &-Logo {
      width: 200px;
      flex-grow: 1;
      background-image: url("../images/Nekster_logoMono.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-Menu {
      height: 100%;
      flex-grow: 1;
      /*display: none;*/
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      position: absolute;
      height: auto;
      top: 0;
      right: 0;

      .selectric {
        background-color: $primary-color;

        .label {
          color: #fff
        }

        /*.button:after {
          color: #fff
        }*/
      }

      &-Item {
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        font-weight: 600;
        text-decoration: none;
        color: $primary-text-on-light-background;
        font-size: .8rem;
        margin-bottom: 16px;
        transition: all 200ms ease;

        padding-right: 0;

        &:hover {
          color: $primary-text-hover-on-light-background
        }
      }
    }
  }

  &-Up {
    position: absolute;
    //background-color: $primary-color;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    transition: top .3s ease-out;

    &--Visible {
      top: -1px;
    }

    &-Header {
      height: 32px;
      display: flex;
      padding: 0 16px;
      justify-content: center;
      align-items: center;
      background-color: $primary-light-color;

      &-Content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-Text {
        max-width: ($max-row-width - 24px - 16px);
        color: $primary-text-on-light-background;
        padding: 0 16px 0 0;
        font-size: .6rem;
        font-weight: 500;
        flex-grow: 1;
        text-align: start;
      }

      &-Close {
        width: 24px;
        height: 24px;
        background-image: url("../images/outline-close-24px.svg");
        background-position: center center;
        background-size: 14px;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    &-Content {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100% - 32px);

      &-Body {
        padding: 24px 32px;
        max-width: $max-row-width;
        margin: 0 auto;
        font-size: .6rem;
        font-weight: 500;
        text-align: justify;
      }
    }

    @media (min-width: $desktop-at) {
      transition: none;

      &-Header {
        height: 40px;

        &-Content {
          max-width: $content-max-width-desktop;
          justify-content: flex-end;
        }

        &-Text {
          max-width: ($content-max-width-desktop / 2)  - 24px - 16px;
          font-size: .7rem;
        }

        &-Close {
          margin-right: 64px + 16px + 7px;
        }
      }

      &-Content {
        display: flex;
        justify-content: center;
        max-height: calc(100% - 40px);

        &-Body {
          flex-grow: 1;
          max-width: $content-max-width-desktop;
          justify-content: flex-end;
          display: flex;
          font-size: .7rem;
          height: min-content;
          padding: 48px 0;

          &-Section {
            max-width: ($content-max-width-desktop / 2);
            margin-right: 64px + 16px + 7px;
          }
        }
      }
    }
  }

  &-Content {
    background-color: $primary-color;
    color: $primary-text-on-dark-background;
    /*padding: 36px 24px;*/
    //min-height: calc(100% - 200px - 45px - 72px);
    position: relative;

    &-Steps {
      position: relative;
      height: 1px;
      width: 100vw;
      background-color: $primary-dark-color;

      &--Active {
        background-color: rgba(255, 255, 255, .4);
      }
    }

    &-Step {
      display: block;
      content: "";
      position: absolute;
      height: 1px;
      min-width: (100vw / $num-of-steps);
      background-color: #fff;
      top: 0;
      bottom: 0;
      left: 0;
      transition: all .2s ease-in-out, min-width .2s ease-in-out;

      &:not([data-step]) {
        background-color: $primary-dark-color;
        min-width: 100vw;
      }

      @for $i from 1 through ($num-of-steps + 1) {
        &[data-step="#{$i}"] {
          left: ($i * (100vw /$num-of-steps));
        }
      }
    }

    &-Header {
      padding: 36px 0 0 0;
    }

    &-Spacer {
      height: 24px;
    }

    &-Body {
      display: flex;
      min-height: 176px;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px 0 40px;


      &--With-Action {
        //padding-bottom: 0;
      }
    }

    #{$this}-Title {
      font-weight: 400;
      margin: 16px 0 16px 0;
      font-size: 1.3rem;
    }

    #{$this}-Heading {
      font-weight: 400;
      margin: 16px 0 16px 0;
      font-size: 1.3rem;
    }

    #{$this}-Heading2 {
      font-weight: 400;
      margin: 16px 0 16px 0;
      font-size: 1.2rem;
    }

    #{$this}-Subtitle {
      display: block;
      //font-weight: 400;
      margin: 8px 0;
      font-size: .8rem;
    }

    #{$this}-LoginInstead {
      display: flex;
      flex-direction: column;
      color: $primary-text-on-dark-background;
      margin: 64px 0 40px;

      &-Text {
        font-size: .75rem;
      }

      &-Link {
        margin-top: 16px;
        text-transform: uppercase;
        text-decoration: none;
        color: $primary-text-on-dark-background;
        font-weight: 500;
        font-size: .8rem;
        cursor: pointer;
      }
    }

    #{$this}-Connect {
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-Wrapper {
        @include containerFullWidth();
        background-color: $primary-light-color;
        color: $primary-text-on-light-background;
      }

      &-Now {
        text-align: start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &-Heading {
          margin: 0;
          text-transform: uppercase;
          font-size: .8rem;
        }

        &-Text {
          font-size: .6rem;
          text-transform: lowercase;
        }
      }

      &-Icons {
        display: flex;
        align-items: center;
      }

      &-Icon {
        text-decoration: none;
        margin-right: 16px;
        width: 20px;
        height: 20px;
        border: 1px solid $primary-text-on-light-background;
        border-radius: 50%;
        padding: 2px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-Swipe {
    min-width: 100vw;
    position: relative;
    background-color: $primary-dark-color;
    overflow-x: hidden;

    &-View {
      display: block;
      background-color: $primary-color;
      padding: 0;
      min-width: 100vw;
      position: relative;
      box-sizing: content-box;
      left: -1px;
      border-left: 1px solid $primary-dark-color;
      border-right: 1px solid $primary-dark-color;
      /*border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;*/
    }
  }

  &-Footer {
    color: $primary-text-on-dark-background;
    font-size: .5rem;
    text-align: end;
    line-height: 47px;

    &-Wrapper {
      border-top: 1px solid $primary-dark-color;
      background-color: $primary-color;
    }
  }

  &-Desktop-Steps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-Wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      /*> * {
        flex-basis: 100%;
      }*/
    }

    &-Content {
      flex-grow: 1;
    }

    &-Container {
      display: none;
    }

    &-Item {
      padding: 0 16px;
      margin-bottom: 24px;
      max-width: 160px;
      width: 100%;
      min-height: 20px;
      text-align: start;
      position: relative;
      font-size: 13px;
      color: rgba(255, 255, 255, .5);
      user-select: none;
      cursor: default;

      > a {
        line-height: 20px;
        text-decoration: none;
        color: inherit;
        width: 100%;
        cursor: inherit;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 2px;
        background-color: rgba(255, 255, 255, .5);
      }

      &--Completed, &--Active {
        font-weight: 600;
      }

      &--Completed {
        cursor: pointer;
        color: rgba(255, 255, 255, .6);

        &:after {
          background-color: rgba(255, 255, 255, .6);
        }
      }

      &--Active {
        color: #fff;

        &:after {
          background-color: #fff;
        }
      }
    }
  }

  [hidden] {
    display: none;
  }

  @media (min-width: $desktop-at) {
    grid-template-rows: 100px auto 56px;

    &-Header {
      //min-height: 100px;
      flex-direction: row;
      align-items: flex-end;

      &-Wrapper {
        //height: 98px;
        background-color: #fff;
        border-bottom: 1px solid $border;
      }

      &-Logo {
        background-image: url("../images/Nekster_logo.png");
        background-position: left center;
        align-self: center;
        height: 48px;
      }

      &-Menu {
        display: flex;

        position: static;
        height: 100%;
        top: unset;
        right: unset;

        .selectric {
          background-color: #fff;

          .label {
            color: $primary-text-on-light-background
          }
        }
      }
    }

    &-Content-Steps {
      display: none;
    }

    &-Content {
      /*display: flex;
      justify-content: center;
      align-items: center;*/
      display: flex;
      justify-content: center;
      align-items: center;

      &-Spacer {
        width: 16px;
      }

      &-Body {
        //flex-direction: row;

        &--Horizontal {
          flex-direction: row;
        }
      }
    }

    &-Connect {
      &-Container {
        //display: none;
      }
    }

    &-Footer {
      font-size: .7rem;
      line-height: 55px;
    }

    &-Desktop-Steps {

      &-Container {
        display: block;
      }
    }

    .Hide-On-Desktop {
      display: none;
    }
  }
}

.loading-dot {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  &:nth-of-type(2) {
    animation-delay: .2s;
  }

  &:nth-of-type(3) {
    animation-delay: .4s;
  }
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}

.datepicker {
  &--day-name {
    color: $primary-text-on-light-background
  }

  &--cell.-selected-, &--cell.-selected-.-current- {
    background: $primary-color;
  }

  &--cell.-selected-.-focus- {
    background: $primary-dark-color;
  }
}

@media (min-width: $desktop-at) {
  :root {
    --content-width: #{$content-max-width-desktop};
  }
}